import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  TextareaAutosize,
} from "@material-ui/core";
import { useParams, Redirect } from "react-router-dom";
import Lil from "@loginline/core";
import ActionFirstChart from "../Components/ActionFirstChart";
import ActionSecondChart from "../Components/ActionSecondChart";
import TableA7StocksDetails from "../Components/TableA7StocksDetails";
import ReactResizeDetector from "react-resize-detector";
import Header from "../Components/Header";
import makeTransparent from "../utils/makeTransparent";
import {
  useBarrieres,
  useClients,
  useComments,
  useIndexations,
  useInstruments,
  usePeriodes,
  useValeurs,
  useValorisations,
} from "../hooks";
import { compareEndDate } from "../utils";

const options = { year: "numeric", month: "numeric", day: "numeric" };
const titles1 = [
  "Constatation initiale",
  "Dernière constatation",
  "Barrière de remboursement",
  "Barrière coupon",
  "Barrière protection",
];
const titles2 = [
  "Index",
  "Date constatation initiale",
  "Valeur constatation initiale",
  "Dernière date de constatation",
  "Dernière valeur de constatation",
  "Date de début",
  "Date de fin",
  "Barrière de remboursement",
  "Valeur barrière de remboursement",
  "Barrière de paiement du coupon",
  "Valeur barrière de paiement du coupon",
  "Barrière de protection du capital",
  "Valeur barrière de protection du capital",
];

const findBarriereValues = (date, barrieres) => {
  var dt = new Date(date);
  const real_date = new Date(dt);
  for (let i = 0; i < barrieres.length; ++i) {
    if (
      real_date >= new Date(barrieres[i].start_date) &&
      real_date <= new Date(barrieres[i].end_date)
    ) {
      return {
        barriere_protection: barrieres[i].valeur_barriere_protection,
        barriere_remboursement: barrieres[i].valeur_barriere_remboursement,
      };
    }
  }
  return undefined;
};

const groupBy = items => {
  const agg = {};
  items.forEach(elem => {
    const cloneElem = { ...elem };
    delete cloneElem.year;
    agg[elem.year] = { ...agg[elem.year], ...cloneElem, year: elem.year };
  });
  return Object.values(agg);
};

export default function StocksDetails() {
  const classes = useStyles();
  const {
    clientId: clientIdString,
    assetClassId: assetClassIdString,
    instrumentId: instrumentIdString,
  } = useParams();
  const clientId = parseInt(clientIdString);
  const assetClassId = parseInt(assetClassIdString);
  const instrumentId = parseInt(instrumentIdString);
  const [width2, setWidth2] = useState(null);
  const [timeDisplay, setTimeDisplay] = useState('all');

  const [clients, loadingClients, errorClients] = useClients();
  const client = clients && clients[clientId];

  const [instruments, loadingInstruments, errorInstruments] = useInstruments([
    instrumentId,
  ]);
  const instrument = instruments && instruments[instrumentId];

  const [valorisations, loadingValorisations, errorValorisations] =
    useValorisations(instrumentId);

  const [indexations, loadingIndexations, errorIndexations] = useIndexations(
    instruments &&
      instruments[instrumentId] &&
      instruments[instrumentId].indexation_ids
  );

  const [valeurs, loadingValeurs, errorValeurs] = useValeurs(
    indexations && indexations.map(indexation => indexation.index_id[0])
  );

  const [periodes, loadingPeriodes, errorPeriodes] = usePeriodes(instrumentId);

  const [barrieres, loadingBarrieres, errorBarrieres] = useBarrieres(
    indexations &&
      indexations.map(indexation => indexation.barriere_ids).reduce((acc, cv) => acc.concat(cv), []),
  );

  const [comments, loadingComments, errorComments] = useComments(
    clientId,
    assetClassId
  );

  const couponDates = useMemo(() => {
    return periodes && periodes.filter(el => el.coupon > 0).map(el => el.end_date);
  }, [periodes]);

  const nextPeriode =
    periodes &&
    periodes.find(periode => new Date(periode.date_constatation) >= new Date());

  let periodComment = "Pas de commentaire disponible.";
  let constatationDate = "";

  if (nextPeriode) {
    periodComment = nextPeriode.commentaire;
    constatationDate = nextPeriode.date_constatation;
  }

  const instrumentComments =
    instrument &&
    comments &&
    instrument.comments &&
    instrument.comments.length > 0 &&
    instrument.comments.map((id) => comments[id]);

  let actualityComment =
    instrumentComments && instrumentComments.length > 0
      ? instrumentComments[0].text
      : "Pas de commentaire disponible";

  // FIRST CHART

  const lastWeekDate = new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
  const lastMonthDate = new Date().getTime() - 1000 * 60 * 60 * 24 * 30;
  const lastYearDate = new Date().getTime() - 1000 * 60 * 60 * 24 * 365;

  const dataValorisations = valorisations && (timeDisplay === 'all' ? valorisations : valorisations.filter(valo =>
    timeDisplay === 'year'
      ? new Date(valo.date) >= lastYearDate
      : timeDisplay === 'month'
      ? new Date(valo.date) >= lastMonthDate
      : new Date(valo.date) >= lastWeekDate
  )).map(valo => ({
    dataDate: new Date(valo.date),
    value: valo.valeur.toFixed(2),
  }));

  // SECOND CHART

  const [history, setHistory] = useState(false);
  const [futur, setFutur] = useState(false);
  const indexNames = indexations && indexations.map(indexation => indexation.index_id[1]);
  const arraySortByDate =
    valeurs &&
    valeurs
      .filter(valeur => new Date(valeur.date) <= new Date())
      .map(valeur => {
        return {
          year: valeur.date,
          [valeur.index_id[1]]: valeur.valeur_cloture,
        };
      });

  let dataSecChart = arraySortByDate && groupBy(arraySortByDate);
  let barrieresDispo = [];
  let barrieresRemb = [];

  if (barrieres) {
    const namedBarrieres = barrieres.reduce((acc, cv) => {
      if (cv.show_barriere) {
        if (acc[cv.indexation_id[1]]) {
          return {
            ...acc,
            [cv.indexation_id[1]]: acc[cv.indexation_id[1]].concat(cv),
          };
        } else {
          return {
            ...acc,
            [cv.indexation_id[1]]: [cv],
          };
        }
      } else {
        return { ...acc };
      }
    }, {});

    dataSecChart = dataSecChart && dataSecChart.map(elt => {
      return {
        ...elt,
        ...Object.keys(elt)
          .filter(key => key !== "year")
          .map(key => ({
            key,
            protection: namedBarrieres[key]
              ? findBarriereValues(elt.year, namedBarrieres[key])
                ? findBarriereValues(elt.year, namedBarrieres[key])
                    .barriere_protection
                : undefined
              : undefined,
            remboursement: namedBarrieres[key]
              ? findBarriereValues(elt.year, namedBarrieres[key])
                ? findBarriereValues(elt.year, namedBarrieres[key])
                    .barriere_remboursement
                : undefined
              : undefined,
          }))
          .reduce(
            (acc, cv) => ({
              ...acc,
              ["B" + cv.key]: cv.protection,
              ["R" + cv.key]: cv.remboursement,
            }),
            {}
          ),
      };
    });

    barrieresDispo = Object.keys(namedBarrieres);

    if (futur) {
      Object.entries(namedBarrieres).forEach(([key, value]) => {
        value.forEach(bar => {
          if (bar.show_barriere) {
            if (new Date(bar.start_date) > new Date()) {
              dataSecChart.push({
                year: bar.start_date,
                ["B" + key]: bar.valeur_barriere_protection,
                ["R" + key]: bar.valeur_barriere_remboursement,
              });
            }
            if (new Date(bar.end_date) > new Date()) {
              const day_before = new Date(bar.end_date);
              day_before.setDate(day_before.getDate() - 1);
              dataSecChart.push({
                year: day_before.toISOString().split("T")[0],
                ["B" + key]: bar.valeur_barriere_protection,
                ["R" + key]: bar.valeur_barriere_remboursement,
              });
            }
          }
        });
      });
    }
  }

  // Repère de couleur date reporting dans le tableauFloca
  const reportingMarker = (startDate, endDate) => {
    if (
      Date.parse(startDate) < Date.now() &&
      Date.parse(endDate) > Date.now()
    ) {
      return classes.reportingMarkerStyle;
    }
  };

  let date_const_init = undefined;
  if (barrieres) {
    if (barrieres[0] === undefined) {
      date_const_init = undefined;
    } else {
      date_const_init = barrieres[0].date_constatation_initiale;
    }
  }

  return (
    <>
      <Header title={instruments ? instruments[instrumentId].name : ""} />

      <Typography
        variant='h4'
        className={classes.date}
        style={{ textAlign: "center" }}
      >
        Reporting au {client ? client.date_analyse_encours_customer : ""}
        {/* {formatDate(new Date())} */}
      </Typography>

      <Box>
        {instrument && instrument.categorie_produit[1] === "Action" ? (
          ""
        ) : (
          <Grid className={classes.wrap}>
            {/* <Box className={classes.blockGray} /> */}

            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Box className={classes.whiteContainer} style={{ minHeight: 235 }}>
                {(loadingValorisations || (valorisations === undefined && !errorValorisations)) ? (
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : errorValorisations ? (
                  <Typography variant='h2'>
                    Une erreur est survenue
                  </Typography>
                ) :
                (
                  <Grid style={{ display: 'flex', flexDirection: 'column', gap: 10, textAlign: 'center', width: '100%' }}>
                    {
                      valorisations.length === 0 ? (
                        <div style={{ minHeight: 400 }}>
                          <Typography style={{ fontSize: 24, fontStyle: 'italic', textAlign: 'center' }}>
                            Aucune donnée disponible pour cet instrument.
                          </Typography>
                          <div style={{textAlign: 'center'}}>
                            <svg version="1.1" x="0px" y="0px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" width="130px">
                              <g>
                                <g>
                                  <path fill="#000000" d="M129.7,200.2c-24.1,0-48.2,0-72.3,0c-6.1,0-6.2-0.2-6.2-6.4c0-17.2-0.1-34.4,0.1-51.5c0-2.8,0.6-5.8,1.7-8.3c7.6-17.3,15.5-34.5,23.1-51.8c1.4-3.3,3.3-4.5,6.9-4.4c31.8,0.1,63.5,0.1,95.3,0c3.6,0,5.4,1.4,6.7,4.6c7.2,17.3,14.5,34.6,21.7,51.9c0.9,2.2,1.5,4.7,1.5,7.1c0.1,17.7,0.1,35.5,0.1,53.3c0,5.2-0.4,5.6-5.8,5.6C178.2,200.2,154,200.2,129.7,200.2z M129.6,197.4c23.7,0,47.3,0,71,0c3.3,0,5-0.7,4.9-4.5c-0.2-16-0.1-32.1,0-48.1c0-3.4-1.1-4.6-4.5-4.5c-11.1,0.1-22.2,0.3-33.3-0.1c-4.7-0.1-6.4,1.3-7.2,5.9c-2.8,16.4-18.8,27.4-35.1,24.7c-13.4-2.2-23.4-11.8-25.6-25.3c-0.7-4.1-2.2-5.4-6.4-5.3c-11.4,0.3-22.8,0.2-34.2,0c-3.9-0.1-5.2,1.2-5.2,5.2c0.2,15.4,0.2,30.9,0,46.3c-0.1,4.3,1.1,5.7,5.6,5.7C82.8,197.3,106.2,197.4,129.6,197.4z M75.5,137.6c7.7,0,14,0.2,20.3-0.1c4.2-0.2,5.8,1.1,6,5.6c0.6,14.3,13.4,25.6,28.2,25.7c15.1,0,27.9-11.3,28.5-25.8c0.2-4.6,2-5.6,6.1-5.5c6.3,0.2,12.6,0,19.9,0c-2.1-6.7-3.8-12.4-5.9-18.1c-0.3-0.9-2.3-1.6-3.5-1.6c-29.9-0.1-59.7-0.1-89.6,0c-1.3,0-3.4,0.9-3.8,1.9C79.6,125.3,77.8,131,75.5,137.6z M130,115.1c14.7,0,29.5,0,44.2,0c3,0,4.5-0.8,4.4-4.1c-0.2-8.8-0.1-17.6,0-26.4c0-3.1-1.2-4-4.1-4c-29.6,0.1-59.2,0.1-88.8,0c-3.1,0-4,1.2-4,4.1c0.1,8.5,0.2,17,0,25.6c-0.1,3.9,1.3,4.9,5,4.9C101.1,115,115.6,115.1,130,115.1z M205.5,137.6c-7.8-18.7-15.3-36.5-22.8-54.2c-0.4,0.1-0.7,0.1-1.1,0.2c-0.1,1.4-0.2,2.7-0.2,4.1c0,6.3,0.4,12.7-0.1,19c-0.7,9.4,1.4,18.3,4.5,27c0.5,1.5,2.1,3.6,3.3,3.7C194.1,137.8,199.1,137.6,205.5,137.6z M53.9,137.6c5.9,0,10.5-0.2,15.1,0.1c3.2,0.2,4.6-1.3,5.5-4.1c3-8.9,5.2-17.9,4.5-27.5c-0.4-5.6,0-11.2-0.1-16.9c0-1.2-0.2-2.4-0.4-3.6c-0.4-0.1-0.8-0.2-1.2-0.3C69.7,102.5,62,119.5,53.9,137.6z"/>
                                  <path fill="#000000" d="M140.5,218.4c7.1,0.6,13.8,1.6,20.5,1.4c4.3-0.1,8.7-1.6,12.8-3.3c2.7-1.1,3.8-0.9,5.5,1.5c3,4.4,6.2,8.7,9.9,12.5c5.8,5.8,13.1,8.7,21.6,8.9c-7.3,3.8-15.1,6-23.3,6.6c-1.6,0.1-3.8-1.5-4.9-2.9c-1.8-2.4-3.7-2.6-6.4-2c-3.1,0.7-6.2,1.3-9.3,1.6c-1.6,0.1-3.4-0.5-4.9-1.3C152.2,236.3,145.6,228.1,140.5,218.4z M200.2,241.4c-0.9-1.2-1.2-1.6-1.5-1.8c-9.1-3.9-15.6-10.6-21-18.7c-0.6-0.9-3-1.6-4.1-1.2c-7.2,2.8-14.4,3.9-22,2.5c-1.8-0.3-3.6-0.3-5.7-0.5c5.9,10.6,15.4,22.1,30.4,17c3.2-1.1,5.4-0.3,7.6,2.2c1.2,1.3,3.6,2.4,5.4,2.3C192.8,243.1,196.2,242.1,200.2,241.4z"/>
                                  <path fill="#000000" d="M226.8,10c5.7,16.7,5.5,41.5-18.5,49.2c0-0.4-0.1-0.9,0-1.1c7.3-9.7,9.4-20.9,9.1-32.6c-0.1-3.1,0.5-5.3,3-7.5C222.8,15.9,224.5,13,226.8,10z M214.8,52.4c11.3-2.8,15.4-26.1,10.1-35.9c-1.9,2.7-4.9,5-5,7.5C219.7,33.5,218.8,42.9,214.8,52.4z"/>
                                  <path fill="#000000" d="M44.8,98.3c-0.9,2.6-2,5.5-3,8.4c-7.5-1.5-13.1-7.5-15.7-17c3,2.2,5.4,4.8,8.5,6.2C37.7,97.3,41.2,97.5,44.8,98.3z"/>
                                  <path fill="#000000" d="M180.8,46.1c3,5.5,1.4,13.3-3.9,19.3c-0.3-2.1-1.1-3.8-0.7-5.1c0.7-2.3,2.2-4.4,2.9-6.7C179.9,51.4,180.2,49.1,180.8,46.1z"/>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      ) : dataValorisations.length === 0 ? (
                        <div style={{ minHeight: 400 }}>
                          <Typography variant='h2'>
                            Aucune donnée disponible dans cette période.
                          </Typography>
                          <div style={{textAlign: 'center'}}>
                            <svg version="1.1" x="0px" y="0px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" width="130px">
                              <g>
                                <g>
                                  <path fill="#000000" d="M129.7,200.2c-24.1,0-48.2,0-72.3,0c-6.1,0-6.2-0.2-6.2-6.4c0-17.2-0.1-34.4,0.1-51.5c0-2.8,0.6-5.8,1.7-8.3c7.6-17.3,15.5-34.5,23.1-51.8c1.4-3.3,3.3-4.5,6.9-4.4c31.8,0.1,63.5,0.1,95.3,0c3.6,0,5.4,1.4,6.7,4.6c7.2,17.3,14.5,34.6,21.7,51.9c0.9,2.2,1.5,4.7,1.5,7.1c0.1,17.7,0.1,35.5,0.1,53.3c0,5.2-0.4,5.6-5.8,5.6C178.2,200.2,154,200.2,129.7,200.2z M129.6,197.4c23.7,0,47.3,0,71,0c3.3,0,5-0.7,4.9-4.5c-0.2-16-0.1-32.1,0-48.1c0-3.4-1.1-4.6-4.5-4.5c-11.1,0.1-22.2,0.3-33.3-0.1c-4.7-0.1-6.4,1.3-7.2,5.9c-2.8,16.4-18.8,27.4-35.1,24.7c-13.4-2.2-23.4-11.8-25.6-25.3c-0.7-4.1-2.2-5.4-6.4-5.3c-11.4,0.3-22.8,0.2-34.2,0c-3.9-0.1-5.2,1.2-5.2,5.2c0.2,15.4,0.2,30.9,0,46.3c-0.1,4.3,1.1,5.7,5.6,5.7C82.8,197.3,106.2,197.4,129.6,197.4z M75.5,137.6c7.7,0,14,0.2,20.3-0.1c4.2-0.2,5.8,1.1,6,5.6c0.6,14.3,13.4,25.6,28.2,25.7c15.1,0,27.9-11.3,28.5-25.8c0.2-4.6,2-5.6,6.1-5.5c6.3,0.2,12.6,0,19.9,0c-2.1-6.7-3.8-12.4-5.9-18.1c-0.3-0.9-2.3-1.6-3.5-1.6c-29.9-0.1-59.7-0.1-89.6,0c-1.3,0-3.4,0.9-3.8,1.9C79.6,125.3,77.8,131,75.5,137.6z M130,115.1c14.7,0,29.5,0,44.2,0c3,0,4.5-0.8,4.4-4.1c-0.2-8.8-0.1-17.6,0-26.4c0-3.1-1.2-4-4.1-4c-29.6,0.1-59.2,0.1-88.8,0c-3.1,0-4,1.2-4,4.1c0.1,8.5,0.2,17,0,25.6c-0.1,3.9,1.3,4.9,5,4.9C101.1,115,115.6,115.1,130,115.1z M205.5,137.6c-7.8-18.7-15.3-36.5-22.8-54.2c-0.4,0.1-0.7,0.1-1.1,0.2c-0.1,1.4-0.2,2.7-0.2,4.1c0,6.3,0.4,12.7-0.1,19c-0.7,9.4,1.4,18.3,4.5,27c0.5,1.5,2.1,3.6,3.3,3.7C194.1,137.8,199.1,137.6,205.5,137.6z M53.9,137.6c5.9,0,10.5-0.2,15.1,0.1c3.2,0.2,4.6-1.3,5.5-4.1c3-8.9,5.2-17.9,4.5-27.5c-0.4-5.6,0-11.2-0.1-16.9c0-1.2-0.2-2.4-0.4-3.6c-0.4-0.1-0.8-0.2-1.2-0.3C69.7,102.5,62,119.5,53.9,137.6z"/>
                                  <path fill="#000000" d="M140.5,218.4c7.1,0.6,13.8,1.6,20.5,1.4c4.3-0.1,8.7-1.6,12.8-3.3c2.7-1.1,3.8-0.9,5.5,1.5c3,4.4,6.2,8.7,9.9,12.5c5.8,5.8,13.1,8.7,21.6,8.9c-7.3,3.8-15.1,6-23.3,6.6c-1.6,0.1-3.8-1.5-4.9-2.9c-1.8-2.4-3.7-2.6-6.4-2c-3.1,0.7-6.2,1.3-9.3,1.6c-1.6,0.1-3.4-0.5-4.9-1.3C152.2,236.3,145.6,228.1,140.5,218.4z M200.2,241.4c-0.9-1.2-1.2-1.6-1.5-1.8c-9.1-3.9-15.6-10.6-21-18.7c-0.6-0.9-3-1.6-4.1-1.2c-7.2,2.8-14.4,3.9-22,2.5c-1.8-0.3-3.6-0.3-5.7-0.5c5.9,10.6,15.4,22.1,30.4,17c3.2-1.1,5.4-0.3,7.6,2.2c1.2,1.3,3.6,2.4,5.4,2.3C192.8,243.1,196.2,242.1,200.2,241.4z"/>
                                  <path fill="#000000" d="M226.8,10c5.7,16.7,5.5,41.5-18.5,49.2c0-0.4-0.1-0.9,0-1.1c7.3-9.7,9.4-20.9,9.1-32.6c-0.1-3.1,0.5-5.3,3-7.5C222.8,15.9,224.5,13,226.8,10z M214.8,52.4c11.3-2.8,15.4-26.1,10.1-35.9c-1.9,2.7-4.9,5-5,7.5C219.7,33.5,218.8,42.9,214.8,52.4z"/>
                                  <path fill="#000000" d="M44.8,98.3c-0.9,2.6-2,5.5-3,8.4c-7.5-1.5-13.1-7.5-15.7-17c3,2.2,5.4,4.8,8.5,6.2C37.7,97.3,41.2,97.5,44.8,98.3z"/>
                                  <path fill="#000000" d="M180.8,46.1c3,5.5,1.4,13.3-3.9,19.3c-0.3-2.1-1.1-3.8-0.7-5.1c0.7-2.3,2.2-4.4,2.9-6.7C179.9,51.4,180.2,49.1,180.8,46.1z"/>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      ) : (
                        <ActionFirstChart
                          data={dataValorisations.reverse()}
                        />
                      )
                    }
                    <Grid className={classes.btnWrapper}>
                      <Button
                        className={timeDisplay === 'all' ? classes.pinkBtn : classes.whiteBtn}
                        onClick={() => {
                          setTimeDisplay('all');
                        }}
                      >
                        Tout
                      </Button>
                      <Button
                        className={timeDisplay === 'year' ? classes.pinkBtn : classes.whiteBtn}
                        onClick={() => {
                          setTimeDisplay('year');
                        }}
                      >
                        Année
                      </Button>
                      <Button
                        className={timeDisplay === 'month' ? classes.pinkBtn : classes.whiteBtn}
                        onClick={() => {
                          setTimeDisplay('month')
                        }}
                      >
                        Mois
                      </Button>
                      <Button
                        className={timeDisplay === 'week' ? classes.pinkBtn : classes.whiteBtn}
                        onClick={() => {
                          setTimeDisplay('week');
                        }}
                      >
                        Semaine
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className={classes.AreaChartTextGrid}
            >
              <Box style={{ backgroundColor: "#878889", marginTop: 80, borderRadius: '0 10px 10px 0' }}>
                <Typography
                  variant='h2'
                  style={{ color: "white", margin: 20, padding: 20 }}
                >
                  Évolution de la valorisation depuis la détention
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}

        {(loadingValeurs || (valeurs === undefined && !errorValeurs)) ? (
          <Box className={`${classes.tableContainer} ${classes.center}`}>
            <CircularProgress />
          </Box>
        ) : valeurs.length > 0 ? (
          <>
            <Grid className={classes.wrap}>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                className={classes.AreaChartTextGrid}
              >
                <Box style={{ backgroundColor: "#878889", marginTop: 80, borderRadius: '10px 0 0 10px' }}>
                  <Typography
                    variant='h2'
                    style={{
                      color: "white",
                      margin: 20,
                      marginBottom: 0,
                      padding: 20,
                    }}
                  >
                    Évolution des index
                  </Typography>
                  <Typography
                    variant='body1'
                    style={{ marginLeft: 20, color: "white" }}
                  >
                    Commentaire période :
                  </Typography>
                  <TextareaAutosize
                    aria-label='minimum height'
                    rowsMin={8}
                    value={
                      periodComment !== ""
                        ? new Date(constatationDate).toLocaleDateString() +
                          " : " +
                          periodComment
                        : actualityComment === ""
                        ? "Pas de commentaire disponible"
                        : actualityComment
                    }
                    style={{
                      marginLeft: 20,
                      margin: 20,
                      borderRadius: 10,
                      border: "none",
                      width: "85%",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Box
                  className={classes.whiteContainer}
                  style={{ display: "block" }}
                >
                  {date_const_init === undefined ? (
                    <>
                      <ReactResizeDetector
                        handleWidth
                        onResize={x2 => setWidth2(x2)}
                      />
                      <ActionSecondChart
                        width={width2}
                        data={dataSecChart}
                        couponDates={couponDates}
                        indexNames={indexNames}
                        barrieresDispo={barrieresDispo}
                        barrieresRemb={barrieresRemb}
                      />
                    </>
                  ) : (
                    <Grid style={{ display: 'flex', flexDirection: 'column', gap: 25 }}>
                      <Grid style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                        <Button
                          onClick={() => setHistory(!history)}
                          className={history ? classes.pinkBtn : classes.whiteBtn}
                        >
                          {"Historique"}
                        </Button>
                        <Button
                          className={futur ? classes.pinkBtn : classes.whiteBtn}
                          onClick={() => setFutur(!futur)}
                        >
                          {"Futur"}
                        </Button>
                      </Grid>
                      <Grid>
                        <ReactResizeDetector
                          handleWidth
                          onResize={x2 => setWidth2(x2)}
                        />
                        <ActionSecondChart
                          width={width2}
                          data={
                            history
                              ? dataSecChart
                              : dataSecChart.filter(
                                  e =>
                                    new Date(e.year) >=
                                    new Date(date_const_init)
                                )
                          }
                          indexNames={indexNames}
                          barrieresDispo={barrieresDispo}
                          barrieresRemb={barrieresRemb}
                          couponDates={couponDates}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <Box />
        )}

        <Grid className={classes.ActionTab} item xs={12} sm={12} md={10}>
          {loadingBarrieres ? (
            <Box className={`${classes.tableContainer} ${classes.center}`}>
              <CircularProgress />
            </Box>
          ) : barrieres && barrieres.length > 0 ? (
            <TableA7StocksDetails
              titles1={titles1}
              titles2={titles2}
              //name={store.index.map((line, i) => line.index_id[1])}
              rows={barrieres.sort(compareEndDate).map((line, index) => [
                <Box
                  key={index}
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.indexation_id[1]}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {new Date(line.date_constatation_initiale).toLocaleDateString(
                    undefined,
                    options
                  )}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.valeur_evaluation.toFixed(2)}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {new Date(line.date_derniere_constatation).toLocaleDateString(
                    undefined,
                    options
                  )}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.valeur_derniere_evaluation.toFixed(2)}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {new Date(line.start_date).toLocaleDateString(
                    undefined,
                    options
                  )}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {new Date(line.end_date).toLocaleDateString(
                    undefined,
                    options
                  )}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.barriere_remboursement + "%"}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.valeur_barriere_remboursement.toFixed(2)}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.barriere_paiement + "%"}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.valeur_barriere_paiement.toFixed(2)}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.barriere_protection + "%"}
                </Box>,
                <Box
                  className={reportingMarker(line.start_date, line.end_date)}
                >
                  {line.valeur_barriere_protection.toFixed(2)}
                </Box>,
              ])}
            />
          ) : (
            <Box />
          )}
        </Grid>
      </Box>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  wrap: {
    display: "flex",
    padding: "60px 100px 30px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  loading: {
    textAlign: "center",
  },
  AreaChartTextGrid: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  AreaChartText: {
    fontSize: 26,
    fontWeight: "bold",
    color: theme.palette.white.main,
    padding: "140px 0 0 60px",
    position: "absolute",
    maxWidth: 240,
    [theme.breakpoints.down("xs")]: {
      padding: 10,
      display: "none",
    },
  },
  ActionTab: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 80,
    marginBottom: 80,
  },
  whiteContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    backgroundColor: theme.palette.white.main,
    boxShadow: `0px 3px 15px ${makeTransparent(
      theme.palette.primary.main,
      0.5
    )}`,
    padding: 60,
    borderRadius: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  blockGray: {
    width: "70%",
    height: "25%",
    backgroundColor: "#878889",
    borderRadius: "0px 0px 0px 40px",
    position: "absolute",
    marginTop: 115,
    zIndex: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '10px',
  },
  whiteBtn: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    padding: 0,
    width: 100,
    height: 32,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      margin: 10,
      width: 80,
    },
  },
  pinkBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    padding: 0,
    width: 100,
    height: 32,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      margin: 10,
      width: 80,
    },
  },
  center: {
    textAlign: "center",
  },
  reportingMarkerStyle: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));
